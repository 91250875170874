<template>
  <div class="systemInfoWarp">
    <ul>
      <li v-for="item in info" :key="item.key" class="tr">
        <div class="tr_left">{{ item.key }}</div>
        <div class="tr_right">{{ item.value }}</div>
      </li>
      <li v-if="info.length % 2 !== 0">
        <div class="tr_left"></div>
        <div class="tr_right"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: [
        {
          key: "服务器操作系统",
          value: "Linux (114.55.34.251)"
        },
        {
          key: "Web 服务器",
          value: "nginx/1.10.2 "
        },
        {
          key: "PHP 版本",
          value: "5.6.30"
        },
        {
          key: "MySQL 版本",
          value: "5.5.56"
        },
        {
          key: "安全模式",
          value: "否"
        },
        {
          key: "安全模式GID ",
          value: "否"
        },
        {
          key: "Socket 支持",
          value: "否"
        },
        {
          key: "时区设置",
          value: "Asia/Shanghai"
        },
        {
          key: "GD 版本",
          value: "GD2 ( JPEG GIF PNG)"
        },
        {
          key: "Zlib 支持",
          value: "是"
        },
        {
          key: "IP 库版本",
          value: "20071024"
        },
        {
          key: "文件上传的最大大小",
          value: "40M"
        },
        {
          key: "程序版本",
          value: "v2.2.2 RELEASE 20170629"
        },
        {
          key: "上线日期",
          value: "2020/05/02"
        },
        {
          key: "编码",
          value: "UTF-8"
        }
      ]
    };
  },
  created() {
    this.$store.commit("breadcrumbList", [
      {
        name: "系统信息",
        path: "systemInfo"
      }
    ]);
  }
};
</script>

<style lang="less" scoped>
.systemInfoWarp {
  padding: 24px;
  ul {
    display: flex;
    flex-wrap: wrap;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    font-size: 14px;
    li {
      line-height: 40px;
      width: 50%;
      display: flex;
      .tr_left {
        width: 240px;
        border-top: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
        background: #f1f8f9;
        text-indent: 70px;
        color: #333333;
      }
      .tr_right {
        flex: 1;
        border-top: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
        text-indent: 5%;
        background: #fff;
        color: #666666;
      }
    }
  }
}
</style>
